'use client';

import parse from 'html-react-parser';
import Container from '@src/components/Shared/Container';
import NextImage from '@src/components/Shared/NextImage';
import RenderBodyCopy from '@src/components/Shared/RenderBodyCopy';
import isEmpty from '@src/utils/isEmpty';
import styles from './HotSpots.module.css';
import { HotSpotsPropTypes } from './HotSpots.types';
import { useFloating, offset, flip, shift } from '@floating-ui/react';
import { useState } from 'react';
const HotSpots: React.FC<HotSpotsPropTypes> = ({
  attr,
  data,
  className = ''
}) => {
  const jsonData = data?.extraAssetsCollection?.items?.[0]?.json;
  if (isEmpty({
    value: jsonData?.hotSpots
  })) return null;
  const [activeIndex, setActiveIndex] = useState<null | number>(null);
  return <Container attr={attr} className={`3xl:[&_*_figure]:left-[5%] 3xl:[&_*_figure]:w-[95%] ${className}`} data-sentry-element="Container" data-sentry-component="HotSpots" data-sentry-source-file="HotSpots.tsx">
      <div className={`${styles.hotSpotWrapper}`}>
        <div className='relative mb-[2rem]'>
          <NextImage objectFit='contain' src={data?.mediaPrimary?.url} alt={data?.mediaPrimary?.description} width={data?.mediaPrimary?.width} aspectRatio={data?.mediaPrimary?.width / data?.mediaPrimary?.height} data-sentry-element="NextImage" data-sentry-source-file="HotSpots.tsx" />
          <div className={`${styles?.hotSpotModal}`}>
            {jsonData?.hotSpots?.map((hotSpot, index) => {
            const {
              floatingStyles,
              refs
            } = useFloating({
              placement: 'bottom',
              middleware: [offset(20), flip(), shift({
                padding: 5
              })]
            });
            return <>
                  <div key={index} ref={refs.setReference} onMouseEnter={() => setActiveIndex(index)} onMouseLeave={() => setActiveIndex(null)} style={{
                position: 'relative',
                margin: '10px 0',
                cursor: 'pointer',
                left: hotSpot?.left,
                top: hotSpot?.top
              }}>
                    <label style={{
                  display: 'block',
                  position: 'absolute',
                  width: '20px',
                  height: '20px',
                  color: '#fff',
                  backgroundColor: '#ff7a00'
                }} className={`${styles?.hotSpotLabel} ${styles?.circle} ${styles?.pulse} block md:inline`}>
                      <strong className='xl:hidden'>{index + 1}</strong>
                    </label>

                    {activeIndex === index && <div className='hidden xl:block' ref={refs.setFloating}
                // className={`${styles?.modalContent}`}
                style={{
                  ...floatingStyles,
                  position: 'absolute',
                  transform: 'translateX(-50%)',
                  left: '10px',
                  top: '35px',
                  background: 'hsla(0,0%,100%,.9)',
                  padding: '1rem',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  textAlign: 'left',
                  zIndex: '4'
                }}>
                        <div className='text-left'>
                          <h3 className={`hotspot__Title mb-[1rem] text-[1.4rem] font-medium`}>
                            {hotSpot?.box?.title}
                          </h3>
                          <p className='text-base'>{parse(hotSpot?.box?.text ?? '')}</p>
                        </div>
                      </div>}
                  </div>
                </>;
          })}
          </div>
        </div>

        <div className='gap-3 mt-[2rem] hidden w-full items-center justify-center text-center xl:flex'>
          <label className={`!my-[0] ${styles?.legend} ${styles?.hotSpotLabel} ${styles?.circle}`}></label>
          <RenderBodyCopy className='ml-[0.5rem] text-[1.6rem]' bodyCopy={data?.extraAssetsCollection?.items?.[0]?.bodyCopy?.json?.content} data-sentry-element="RenderBodyCopy" data-sentry-source-file="HotSpots.tsx" />
        </div>

        <div className='gap-3 flex flex-col sm:mx-auto sm:max-w-[60rem] xl:hidden'>
          {jsonData.hotSpots.map((hotSpot, index) => {
          const cleanText = hotSpot?.box?.text.replace(/<(.|\n)*?>/g, '');
          const uniqueId = `mobile-hotspot-${index}`;
          return <>
                <div key={uniqueId} className='items-top bottom-[3rem] mt-[1rem] flex w-full items-start gap-[1.5rem] text-left text-[1.6rem]'>
                  <label className={`legend tracking-[6px] ${styles?.legend} ${styles?.hotSpotLabel} ${styles?.circle}`}>
                    {index + 1}
                  </label>
                  <span className='leading-[2rem]'>
                    <span className='font-[600]'>{hotSpot?.box?.title}:</span> {cleanText}
                  </span>
                </div>
              </>;
        })}
        </div>
      </div>
    </Container>;
};
export default HotSpots;